/*
========================================
.measure
----------------------------------------
property: max-width
----------------------------------------
usage:
  .measure-[key]
----------------------------------------
output:
  max-width: [value];
----------------------------------------
example:
  .measure-4 {
    max-width: 68ex; }
----------------------------------------
*/

$u-measure: (
  measure: (
    base: "measure",
    modifiers: null,
    values: map-collect(get-palettes($measure-palettes), $measure-manual-values),
    settings: $measure-settings,
    property: "max-width",
    type: "utility",
  ),
);
