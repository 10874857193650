/*
========================================
background-color
----------------------------------------
usage:
  .bg-[key]
----------------------------------------
output:
  background-color: [value];
----------------------------------------
example:
  .bg-red-50v {
    background-color: #be4900; }
----------------------------------------
*/

$u-background-color: (
  background-color: (
    base: "bg",
    modifiers: null,
    property: "background-color",
    values:
      map-collect(
        get-palettes($background-color-palettes),
        get-palettes("palette-color-required"),
        get-palettes($global-color-palettes),
        $background-color-manual-values
      ),
    settings: $background-color-settings,
    type: "utility",
  ),
);
