/*
========================================
border-width
----------------------------------------
usage:
  .border-[modifier]*-width-[key]
----------------------------------------
output:
  border-[modifier]-width: [value];
----------------------------------------
example:
  .border-top-width-1px {
    border-top-width: 1px; }
----------------------------------------
*/

$u-border-width: (
  border-width: (
    base: "border",
    modifiers: (
      "width": "-width",
      "y-width": (
        "-top-width",
        "-bottom-width",
      ),
      "x-width": (
        "-left-width",
        "-right-width",
      ),
      "top-width": "-top-width",
      "right-width": "-right-width",
      "bottom-width": "-bottom-width",
      "left-width": "-left-width",
    ),
    values:
      map-collect(
        get-palettes($border-width-palettes),
        $border-width-manual-values
      ),
    settings: $border-width-settings,
    property: "border",
    type: "utility",
  ),
);
