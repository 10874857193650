/*
========================================
min-height
----------------------------------------
usage:
  .minh-[key]
----------------------------------------
output:
  min-height: [value];
----------------------------------------
example:
  .minh-card {
    min-height: 200px; }
----------------------------------------
*/

$u-min-height: (
  min-height: (
    base: "minh",
    modifiers: null,
    values:
      map-collect(get-palettes($min-height-palettes), $min-height-manual-values),
    settings: $min-height-settings,
    property: "min-height",
    type: "utility",
  ),
);
