/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
*/

// -------------------------------------
// Import individual theme settings
@import "theme/uswds-theme-general";
@import "theme/uswds-theme-typography";
@import "theme/uswds-theme-spacing";
@import "theme/uswds-theme-color";
@import "theme/uswds-theme-utilities";
@import "theme/uswds-theme-components";

// Set correct paths for use with asset pipeline
$theme-font-path: "../../node_modules/uswds/dist/fonts";
$theme-image-path: "../../node_modules/uswds/dist/img";

// -------------------------------------
// Import individual USWDS packages...
@import "scss/packages/required";
@import "scss/packages/global";

@import "scss/packages/uswds-components";
@import "scss/packages/uswds-utilities";
@import "theme/uswds-theme-custom-styles";

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/v4-shims.scss";

@import "pages/index";

// default mode hide banner
.usa-banner__content {
  display: hidden;
}

@media screen and (min-width: 640px) {
  .tablet\:width-1\/3 {
    width: 33%;
  }
  .tablet\:float-left {
    float: left;
  }
}

@media screen and (min-width: 640px) {
  .paginate-link {
    display: initial;
  }
  .paginate-button {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .paginate-link {
    display: none;
  }
  .paginate-button {
    display: block;
  }
}

// Add your SASS/CSS here
// default mode hide banner
.usa-banner__content,
.usa-nav__submenu {
  display: hidden;
}

.site-banner {
  background-color: color("base-darkest");

  .usa-banner__content {
    color: color("white");
  }

  .usa-banner__header-action {
    color: color("base-light");
    &::after {
      mask: url("../images/content/chevron.svg") no-repeat center /
        1.3128205128ex 0.8ex;
      background-color: color("base-light");
    }
    &:hover {
      color: color("white");
      &::after {
        background-color: color("white");
      }
    }
  }

  .usa-banner__button[aria-expanded="true"]::before {
    background: transparent !important;
  }

  .usa-banner__button[aria-expanded="true"]::after {
    @include u-bg("white");
    mask: url("../images/content/close-alt.svg") no-repeat center/1rem 1rem;
  }

  @include at-media("tablet") {
    .usa-banner__button,
    .usa-banner__button[aria-expanded="true"] {
      color: color("base-light");
      &::after {
        mask: url("../images/content/chevron.svg") no-repeat center /
          1.3128205128ex 0.8ex;
        background-color: color("base-light");
      }
      &:hover {
        color: color("white");
        &::after {
          background-color: color("white");
        }
      }
    }
  }

  .usa-banner__header-text {
    color: color("white");
  }

  .usa-banner__header-action {
    color: color("gray-30");
  }
}

.site-footer {
  @include u-bg("accent-warm");
  @include u-font("body", "3xs");
  @include u-color("ink");

  a {
    @include u-color("ink");

    &:visited {
      @include u-color("ink");
    }
    &:hover {
      @include u-color("primary-darker");
    }
  }
}

@media screen and (min-width: 640px) {
  .tablet\:width-1\/3 {
    width: 33%;
  }
  .tablet\:float-left {
    float: left;
  }

  //force grid-row wide gutter docs well
  .grid-row.grid-gap .usa-prose.padding-x-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 640px) {
  .paginate-link {
    display: initial;
  }
  .paginate-button {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .paginate-link {
    display: none;
  }
  .paginate-button {
    display: block;
  }
}

//Docs
.usa-section.bg-accent-warm-light {
  @include at-media("tablet") {
    @include u-padding-y(8);
  }
}

.usa-layout-docs-sidenav {
  @include at-media("mobile") {
    @include u-flex("fill");
    @include u-order("last");
  }
  @include at-media("desktop") {
    order: 0;
    flex: none;
  }
}

// Pricing Page
.usa-content.bg-accent-warm-light {
  @include at-media("tablet") {
    @include u-padding-top(4);
    @include u-padding-bottom(8);
  }
}

.products-pricing {
  .usa-table tr {
    @include u-padding-y(3);
    border-top: units(1px) solid color("base-lighter");
  }

  .usa-table td,
  .usa-table th {
    background-color: transparent;
    border: none;
    @include u-padding-left(0);
    @include u-padding-right($theme-table-column-gap / 2);
    @include u-padding-y(0.5);
  }
}

.pricing-calculations {
  .pricing-line-item {
    border-bottom: 1px dotted #444;
  }

  span {
    @include u-float("right");
  }
}

// Home
ul.home-list {
  list-style: none;
  @include u-border-bottom(1px);
  @include u-border-bottom("base-light");

  li {
    @include u-border-top(1px);
    @include u-border-top("base-light");
    @include u-padding-y("105");

    &:first-of-type {
      border-top: 1px solid "base-light";
    }
  }
}

a.cg-arrow {
  @include u-color("ink");
  @include u-font-family("ui");

  display: block;

  &:before {
    content: "\02192";
    display: inline-block;
    @include u-padding-right("105");
    @include u-text("secondary-vivid");
    font-family: "Times New Roman", Arial, sans-serif;
  }
}
@include at-media("tablet") {
  .bar-top {
    @include u-padding-top(4);
    &:before {
      @include u-bg("base-light");
      content: "";
      height: 2px;
      left: 16px;
      position: absolute;
      top: 0;
      width: 45px;
    }
  }
}

.site-welcome {
  @include u-margin-top(4);

  h1 {
    @include u-font-size("sans", 16);
  }
  ul {
    padding: 0;
    margin: 30px 0 0 0;
    max-width: 500px;

    li {
      font-size: 22px;

      a {
        @include u-padding-y(3);

        &:before {
          width: 40px;
        }
      }
    }
  }
  p.usa-intro {
    @include u-font-size("serif", "lg");
  }
}

a {
  @include u-color("primary-dark");

  &:hover {
    @include u-color("primary-darker");
  }
}

.sign-up-intro {
  background-color: "primary-darkest";
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 1040px 148px;

  @include at-media("desktop") {
    background-image: url("../images/content/peaks.svg");
  }
}

.usa-section.why-cloud,
.usa-section.is-cloud-right,
.usa-section.packages {
  h2 {
    @include u-margin-top(0);
  }
  h3 {
    @include u-margin-y(1);
  }
  .usa-prose {
    @include u-margin-bottom(3);
  }
}

.is-cloud-right .home-list {
  padding-left: 0;
}

.partner-card {
  @include u-margin(1);
  @include u-padding-y(1);
  @include u-border(1px);
  @include u-border("base-light");

  h5 {
    @include u-margin-y(1);
  }

  a {
    @include u-color("ink");
  }

  img {
    @include u-square(9);
    @include u-float("left");
    @include u-padding-right(2);
  }
}

// code blocks

code {
  @include u-bg("accent-warm-light");
  @include u-padding-x(1);
  @include u-padding-y(1);
  @include u-font-size("mono", "xs");
  @include u-display("block");
  border-radius: radius("sm");
  word-wrap: break-word;
  overflow: auto;
  line-height: line-height("mono", 1);
}

code.language-plaintext {
  @include u-display("inline");
}

.usa-section.want-more {
  @include at-media("tablet") {
    @include u-padding-bottom(8);
  }
}

// *******
//Mermaid styles
// *******
.cg-diagrams-hidden {
  position: absolute;
  top: -9999px;
  left: -99999px;
}

.cg-diagrams-stage {
  background: #f1f1f1 !important;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto",
    "Arial", sans-serif !important;
}
.cg-diagrams-stage .label {
  color: #ffffff !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.cg-diagrams,
.cg-diagrams-stage {
  overflow: scroll !important;
  width: 100% !important;
}

.cluster rect {
  fill: #f1f1f1 !important;
  stroke: color("primary-vivid") !important;
  stroke-width: 2px !important;
}

.cluster text {
  font-size: 1.7rem !important;
  font-weight: 700 !important;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto",
    "Arial", sans-serif !important;
  font-size: 17px !important;
}

.node rect,
.node circle,
.node ellipse,
.node polygon {
  fill: color("primary") !important;
  stroke: color("primary-dark") !important;
  stroke-width: 2px !important;
}

.node .label,
.node .nodeLabel {
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto",
    "Arial", sans-serif !important;
  color: white !important;
}

.edgePath .path {
  stroke: color("primary-dark") !important;
  stroke-width: 2px !important;
}

.edgePath marker path {
  fill: color("primary-dark") !important;
}

.edgeLabel {
  background-color: #ffffff !important;
  border: #ffffff 5px solid !important;
  color: #000000 !important;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto",
    "Arial", sans-serif !important;
  font-family: 17px !important;
}
.edgeLabel:empty {
  border: none !important;
}

rect {
  fill: color("primary-vivid") !important;
  stroke: color("primary-dark") !important;
  stroke-width: 2px !important;
}
rect.note {
  fill: #e1f3f8 !important;
  stroke: color("primary")-light !important;
  stroke-width: 2px !important;
}
rect.labelBox {
  fill: color("primary-vivid") !important;
  stroke: color("primary") !important;
}
rect.actor {
  fill: "priamry-vivid" !important;
  stroke: color("primary-dark") !important;
}

#crosshead path,
#arrowhead path {
  fill: color("primary-dark") !important;
}

line[class^="messageLine"],
line.actor-line {
  fill: color("primary-dark") !important;
  stroke: color("primary-dark") !important;
  stroke-width: 2px !important;
}

line.loopLine {
  fill: color("primary-dark") !important;
  stroke: color("primary") !important;
  stroke-width: 2px !important;
}

text.messageText {
  font-family: "Bitstream Vera Sans Mono", "Consolas", "Courier", monospace !important;
  text-shadow: 2px 2px 0 #ffffff, 1px 1px 0 #5b616b !important;
  fill: color("primary-vivid") !important;
  font-size: 1rem !important;
}

text.actor {
  fill: #ffffff !important;
}

text.noteText {
  fill: #212121 !important;
  font-size: 1.25rem !important;
}

text.labelText {
  font-family: "Merriweather", "Georgia", "Cambria", "Times New Roman", "Times",
    serif !important;
  fill: #dce4ef !important;
  font-size: 1.25rem !important;
  text-transform: uppercase !important;
}

text.loopText {
  font-family: "Merriweather", "Georgia", "Cambria", "Times New Roman", "Times",
    serif !important;
  font-size: 1rem !important;
  font-style: oblique !important;
}

blockquote .quote {
  font-size: 1.4rem;
  font-style: italic;
}

blockquote .source {
  display: block;
  font-size: 0.9rem;
}

.content-image figcaption {
  font-size: 0.8rem;
}

.custom-story-preview-logo {
  display: flex;
  align-items: center;
}

// missing syntax highlighting styles -- ported from Rouge / old Jekyll build
.highlight table td {
  padding: 5px;
}
.highlight table pre {
  margin: 0;
}
.highlight,
.highlight .w,
.highlight code {
  color: #f4f5fb;
  background-color: #04050e;
  -webkit-font-smoothing: antialiased;
  line-height: 1.6;
  font-size: 0.75rem;
}
.highlight .err {
  color: #151515;
  background-color: #ac4142;
}
.highlight .c,
.highlight .ch,
.highlight .cd,
.highlight .cm,
.highlight .cpf,
.highlight .c1,
.highlight .cs {
  color: #505050;
}
.highlight .cp {
  color: #f4bf75;
}
.highlight .nt {
  color: #f4bf75;
}
.highlight .o,
.highlight .ow {
  color: #b2b8dc;
}
.highlight .p,
.highlight .pi {
  color: #8794db;
}
.highlight .gi {
  color: #90a959;
}
.highlight .gd {
  color: #c74345;
}
.highlight .gh {
  color: #6a9fb5;
  background-color: #151515;
  font-weight: bold;
}
.highlight .k,
.highlight .kn,
.highlight .kp,
.highlight .kr,
.highlight .kv {
  color: #cd80be;
}
.highlight .kc {
  color: #de7c2c;
}
.highlight .kt {
  color: #6cd69e;
}
.highlight .kd {
  color: #6cd69e;
}
.highlight .s,
.highlight .sa,
.highlight .sb,
.highlight .sc,
.highlight .dl,
.highlight .sd,
.highlight .s2,
.highlight .sh,
.highlight .sx,
.highlight .s1 {
  color: #b7d675;
}
.highlight .sr {
  color: #89cdc1;
}
.highlight .si {
  color: #c97446;
}
.highlight .se {
  color: #c97446;
}
.highlight .nn {
  color: #f4bf75;
}
.highlight .nc {
  color: #f4bf75;
}
.highlight .no {
  color: #f4bf75;
}
.highlight .n {
  color: #d6f9e8;
}
.highlight .na {
  color: #92cae3;
}
.highlight .nf {
  color: #b6e9ff;
}
.highlight .nx {
  color: #18c8ff;
}
.highlight .nb {
  color: #b286fe;
}
.highlight .m,
.highlight .mb,
.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .il,
.highlight .mo,
.highlight .mx {
  color: #90a959;
}
.highlight .ss {
  color: #90a959;
}

#table-of-contents {
  @include u-border($theme-color-base-lighter);
  border-width: 1px;
  border-style: solid;
  padding: 0 2em;
  background-color: color($theme-color-accent-warm-light);
}

#table-of-contents ol {
  font-size: 0.9rem;
  padding: 0;
  list-style-position: inside;
}
