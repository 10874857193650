/*
========================================
font-family
----------------------------------------
usage:
  .family-[key]
----------------------------------------
output:
  font-family: [value]
----------------------------------------
example:
  .family-sans {
    font-family: 'United Sans webfont',
                system,
                -apple-system,
                BlinkMacSystemFont,
                'Roboto',
                'Helvetica Neue',
                'Helvetica',
                'Arial',
                sans-serif; }
----------------------------------------
*/

$u-font-family: (
  font-family: (
    base: "font-family",
    modifiers: null,
    values:
      map-collect(
        get-palettes($font-family-palettes),
        $font-family-manual-values
      ),
    settings: $font-family-settings,
    property: "font-family",
    type: "utility",
  ),
);
