/*
========================================
flex-direction
----------------------------------------
usage:
  .flex-direction-[value]
----------------------------------------
output:
  flex-direction: [value]
----------------------------------------
example:
  .flex-direction-row {
    flex-direction: row; }
----------------------------------------
*/

$u-flex-direction: (
  flex-direction: (
    base: "flex",
    modifiers: null,
    values:
      map-collect(
        get-palettes($flex-direction-palettes),
        $flex-direction-manual-values
      ),
    settings: $flex-direction-settings,
    property: "flex-direction",
    type: "utility",
  ),
);
