/*
========================================
outline
----------------------------------------
sets width and style:solid
----------------------------------------
usage:
  .outline-[modifier]
----------------------------------------
output:
  outline: [modifier] solid;
----------------------------------------
example:
  .outline-g05 {
    outline: 4px solid }
----------------------------------------
*/

$u-outline: (
  outline: (
    base: "outline",
    modifiers: null,
    values: map-collect(get-palettes($outline-palettes), $outline-manual-values),
    settings: $outline-settings,
    valuePrepend: null,
    valueAppend: " solid",
    property: "outline",
    type: "utility",
  ),
);
