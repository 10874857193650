/*
========================================
font
----------------------------------------
sets font-size and font-family
----------------------------------------
usage:
  .size-[key]-[key]
----------------------------------------
output:
  font-family: [value];
  font-size: [value];
----------------------------------------
example:
  .size-sans-s3 {
    font-face: '18Franklin-webfont',
                system,
                -apple-system,
                BlinkMacSystemFont,
                'Roboto',
                'Helvetica Neue',
                'Helvetica',
                'Arial',
                sans-serif;
    font-size: 1rem; }
----------------------------------------
*/

$u-font: (
  font: (
    base: "font",
    modifiers: null,
    values: map-collect(get-palettes($font-palettes), $font-manual-values),
    settings: $font-settings,
    property: "font-size",
    type: "utility",
  ),
);
