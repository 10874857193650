/*
========================================
background-color
----------------------------------------
usage:
  .border-[color]
----------------------------------------
output:
  border[-color]: [color];
----------------------------------------
example:
  .border-red {
    border-color: #be4900; }
----------------------------------------
*/

$u-border-color: (
  border-color: (
    property: "border-color",
    base: "border",
    modifiers: null,
    values:
      map-collect(
        $tokens-color-required,
        get-palettes($border-color-palettes),
        get-palettes($global-color-palettes),
        $border-color-manual-values
      ),
    settings: $border-color-settings,
    type: "utility",
  ),
);
