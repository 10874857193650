/*
========================================
font-weight
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-weight: [value];
----------------------------------------
example:
  .text-300 {
    font-weight: 300; }
----------------------------------------
*/

$u-font-weight: (
  font-weight: (
    base: "text",
    modifiers: null,
    values:
      map-collect(
        get-palettes($font-weight-palettes),
        $font-weight-manual-values
      ),
    settings: $font-weight-settings,
    property: "font-weight",
    type: "utility",
  ),
);
