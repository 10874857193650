/*
========================================
width
----------------------------------------
usage:
  .width-[key]
----------------------------------------
output:
  width: [value];
----------------------------------------
example:
  .width-75ct {
    width: 75%; }
----------------------------------------
*/

$u-width: (
  width: (
    base: "width",
    modifiers: null,
    values: map-collect(get-palettes($width-palettes), $width-manual-values),
    settings: $width-settings,
    property: "width",
    type: "utility",
  ),
);
