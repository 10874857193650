/*
========================================
.right
----------------------------------------
property: right
----------------------------------------
usage:
  .right-[key]
----------------------------------------
output:
  right: [value];
----------------------------------------
example:
  .right-n2px {
    right: -2px; }
----------------------------------------
*/

$u-right: (
  right: (
    base: "right",
    modifiers: null,
    values: map-collect(get-palettes($right-palettes), $right-manual-values),
    settings: $right-settings,
    property: "right",
    type: "utility",
  ),
);
