/*
========================================
display
----------------------------------------
usage:
  .display-[key]
----------------------------------------
output:
  display: [value]
----------------------------------------
example:
  .display-none {
    display: none; }
----------------------------------------
*/

$u-display: (
  display: (
    base: "display",
    modifiers: null,
    values: map-collect(get-palettes($display-palettes), $display-manual-values),
    settings: $display-settings,
    property: "display",
    type: "utility",
  ),
);
