/*
========================================
flex
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex: [value];
----------------------------------------
example:
  .flex-1 {
    flex: 1 0 0; }

  .flex-fill {
    flex: 1 0 0; }

  .flex-auto {
    flex: none; }
----------------------------------------
*/

$u-flex: (
  flex: (
    base: "flex",
    modifiers: null,
    values: map-collect(get-palettes($flex-palettes), $flex-manual-values),
    settings: $flex-settings,
    property: "flex",
    type: "utility",
  ),
);
