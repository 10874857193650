/*
========================================
ASPECT
----------------------------------------
usage:
  .aspect-[value]
----------------------------------------
output:
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 100%;
----------------------------------------
example:
  .aspect-16x9
----------------------------------------
*/

$add-aspect: (
  add-aspect: (
    base: "add-aspect",
    modifiers: null,
    values: (
      9x16: (
        slug: "9x16",
        isReadable: true,
        content: relative,
        extend: (
          "box-sizing": border-box,
          "height": 0,
          "overflow": hidden,
          "padding": 0 0 177.77778%,
        ),
      ),
      1x1: (
        slug: "1x1",
        isReadable: true,
        content: relative,
        extend: (
          "box-sizing": border-box,
          "height": 0,
          "overflow": hidden,
          "padding": 0 0 100%,
        ),
      ),
      4x3: (
        slug: "4x3",
        isReadable: true,
        content: relative,
        extend: (
          "box-sizing": border-box,
          "height": 0,
          "overflow": hidden,
          "padding": 0 0 75%,
        ),
      ),
      16x9: (
        slug: "16x9",
        isReadable: true,
        content: relative,
        extend: (
          "box-sizing": border-box,
          "height": 0,
          "overflow": hidden,
          "padding": 0 0 56.25%,
        ),
      ),
      2x1: (
        slug: "2x1",
        isReadable: true,
        content: relative,
        extend: (
          "box-sizing": border-box,
          "height": 0,
          "overflow": hidden,
          "padding": 0 0 50%,
        ),
      ),
    ),
    settings: $add-aspect-settings,
    property: "position",
    type: "object",
  ),
);
