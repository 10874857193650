/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.13.1
----------------------------------------
COLOR SETTINGS
----------------------------------------
Read more about settings and
USWDS color tokens in the documentation:
https://designsystem.digital.gov/design-tokens/color
----------------------------------------
*/

/*
----------------------------------------
Theme palette colors
----------------------------------------
*/

// Base colors
$theme-color-base-family:           'gray-cool';
$theme-color-base-lightest:         'gray-cool-3';
$theme-color-base-lighter:          'gray-cool-10';
$theme-color-base-light:            'gray-cool-20';
$theme-color-base:                  'gray-cool-40';
$theme-color-base-dark:             'gray-cool-70';
$theme-color-base-darker:           'gray-cool-80';
$theme-color-base-darkest:          'gray-warm-90';
$theme-color-base-ink:              'gray-warm-90';

// Primary colors
$theme-color-primary-family:        'blue-warm';
$theme-color-primary-lightest:      false;
$theme-color-primary-lighter:       'blue-warm-10';
$theme-color-primary-light:         'blue-warm-40v';
$theme-color-primary:               'blue-warm-50v';
$theme-color-primary-vivid:         'blue-warm-50v';
$theme-color-primary-dark:          'blue-warm-60';
$theme-color-primary-darker:        'blue-warm-70v';
$theme-color-primary-darkest:       'blue-warm-80v';

// Secondary colors
$theme-color-secondary-family:      'red';
$theme-color-secondary-lightest:    false;
$theme-color-secondary-lighter:     'red-10v';
$theme-color-secondary-light:       'red-20';
$theme-color-secondary:             'red-40v';
$theme-color-secondary-vivid:       'red-40v';
$theme-color-secondary-dark:        'red-50';
$theme-color-secondary-darker:      'red-60v';
$theme-color-secondary-darkest:     'red-70';

// Accent warm colors
$theme-color-accent-warm-family:    'grey-warm';
$theme-color-accent-warm-lightest:  false;
$theme-color-accent-warm-lighter:   false;
$theme-color-accent-warm-light:     'gray-warm-4';
$theme-color-accent-warm:           'gray-warm-10';
$theme-color-accent-warm-dark:      'gray-warm-20';
$theme-color-accent-warm-darker:    'gray-warm-40';
$theme-color-accent-warm-darkest:   'gray-warm-60';

// Accent cool colors
$theme-color-accent-cool-family:    'cyan';
$theme-color-accent-cool-lightest:  'cyan-5';
$theme-color-accent-cool-lighter:   'cyan-10v';
$theme-color-accent-cool-light:     'cyan-20';
$theme-color-accent-cool:           'cyan-30v';
$theme-color-accent-cool-dark:      'cyan-40v';
$theme-color-accent-cool-darker:    'cyan-50';
$theme-color-accent-cool-darkest:   'cyan-60';

/*
----------------------------------------
State palette colors
----------------------------------------
*/

// Error colors
$theme-color-error-family:     'red-warm';
$theme-color-error-lighter:    'red-warm-10';
$theme-color-error-light:      'red-warm-30v';
$theme-color-error:            'red-warm-50v';
$theme-color-error-dark:       'red-60v';
$theme-color-error-darker:     'red-70';

// Warning colors
$theme-color-warning-family:   'gold';
$theme-color-warning-lighter:  'yellow-5';
$theme-color-warning-light:    'yellow-10v';
$theme-color-warning:          'gold-20v';
$theme-color-warning-dark:     'gold-30v';
$theme-color-warning-darker:   'gold-50v';

// Success colors
$theme-color-success-family:   'green-cool';
$theme-color-success-lighter:  'green-cool-5';
$theme-color-success-light:    'green-cool-20v';
$theme-color-success:          'green-cool-40v';
$theme-color-success-dark:     'green-cool-50';
$theme-color-success-darker:   'green-cool-60';

// Info colors
$theme-color-info-family:      'cyan';
$theme-color-info-lighter:     'cyan-5';
$theme-color-info-light:       'cyan-20';
$theme-color-info:             'cyan-30v';
$theme-color-info-dark:        'cyan-40v';
$theme-color-info-darker:      'blue-cool-60';

// Disabled colors
$theme-color-disabled-family:  'gray';
$theme-color-disabled-light:   'gray-10';
$theme-color-disabled:         'gray-20';
$theme-color-disabled-dark:    'gray-30';

// Emergency colors
$theme-color-emergency: "red-warm-60v";
$theme-color-emergency-dark: "red-warm-80";

/*
----------------------------------------
General colors
----------------------------------------
*/

// Body
$theme-body-background-color: "white";

// Text
$theme-text-color: "ink";
$theme-text-reverse-color: "white";

// Links
$theme-link-color:             'primary-dark';
$theme-link-visited-color:     'violet-70v';
$theme-link-hover-color:       'primary-darker';
$theme-link-active-color:      'primary-darker';
$theme-link-reverse-color:     'base-lighter';
$theme-link-reverse-hover-color: 'base-lightest';
$theme-link-reverse-active-color: 'white';
