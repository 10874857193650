/*
========================================
max-width
----------------------------------------
usage:
  .maxw-[key]
----------------------------------------
output:
  max-width: [value];
----------------------------------------
example:
  .maxw-desktop {
    max-width: 960px; }
----------------------------------------
*/

$u-max-width: (
  max-width: (
    base: "maxw",
    modifiers: null,
    values:
      map-collect(get-palettes($max-width-palettes), $max-width-manual-values),
    settings: $max-width-settings,
    property: "max-width",
    type: "utility",
  ),
);
