/*
========================================
color
----------------------------------------
usage:
  .cursor-[key]
----------------------------------------
output:
  cursor: [value];
----------------------------------------
example:
  .cursor-pointer {
    cursor: pointer; }
----------------------------------------
*/

$u-cursor: (
  cursor: (
    base: "cursor",
    modifiers: null,
    values: map-collect(get-palettes($cursor-palettes), $cursor-manual-values),
    settings: $cursor-settings,
    property: "cursor",
    type: "utility",
  ),
);
