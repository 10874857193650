/*
========================================
text-align
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  align: [value];
----------------------------------------
example:
  .text-left {
    align: left; }
----------------------------------------
*/

$u-text-align: (
  text-align: (
    base: "text",
    modifiers: null,
    values:
      map-collect(get-palettes($text-align-palettes), $text-align-manual-values),
    settings: $text-align-settings,
    property: "text-align",
    type: "utility",
  ),
);
