@mixin button-disabled {
  @include add-knockout-font-smoothing;
  background-color: color("disabled");
  color: color("white");

  &:hover,
  &.usa-button--hover,
  &:active,
  &.usa-button--active,
  &:focus,
  &.usa-focus {
    background-color: color("disabled");
    border: 0;
    box-shadow: none;
  }
}
