/*
========================================
.bottom
----------------------------------------
property: bottom
----------------------------------------
usage:
  .bottom-[key]
----------------------------------------
output:
  bottom: [value];
----------------------------------------
example:
  .bottom-n2px {
    bottom: -2px; }
----------------------------------------
*/

$u-bottom: (
  bottom: (
    base: "bottom",
    modifiers: null,
    values: map-collect(get-palettes($bottom-palettes), $bottom-manual-values),
    settings: $bottom-settings,
    property: "bottom",
    type: "utility",
  ),
);
