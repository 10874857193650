/*
========================================
align-items
----------------------------------------
usage:
  .flex-align-[key]
----------------------------------------
output:
  align-items: [value];
----------------------------------------
example:
  .flex-align-start {
    align-items: flex-start; }
----------------------------------------
*/

$u-align-items: (
  align-items: (
    base: "flex",
    modifiers: null,
    values:
      map-collect(
        get-palettes($align-items-palettes),
        $align-items-manual-values
      ),
    settings: $align-items-settings,
    property: "align-items",
    type: "utility",
  ),
);
