/*
========================================
line-height
----------------------------------------
usage:
  .line-height-[value]
----------------------------------------
output:
  line-height: [value];
----------------------------------------
example:
  .line-height-sans-2 {
    line-height: 1.15; }
----------------------------------------
*/

$u-line-height: (
  line-height: (
    base: "line-height",
    modifiers: null,
    values:
      map-collect(
        get-palettes($line-height-palettes),
        $line-height-manual-values
      ),
    settings: $line-height-settings,
    property: "line-height",
    type: "utility",
  ),
);
