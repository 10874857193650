/*
========================================
.top
----------------------------------------
property: top
----------------------------------------
usage:
  .top-[key]
----------------------------------------
output:
  top: [value];
----------------------------------------
example:
  .top-n2px {
    top: -2px; }
----------------------------------------
*/

$u-top: (
  top: (
    base: "top",
    modifiers: null,
    values: map-collect(get-palettes($top-palettes), $top-manual-values),
    settings: $top-settings,
    property: "top",
    type: "utility",
  ),
);
