/*
========================================
vertical-align
----------------------------------------
usage:
  .vertical-align-[value]
----------------------------------------
output:
  vertical-align: [value]
----------------------------------------
example:
  .vertical-align-top {
    vertical-align: top; }
----------------------------------------
*/

$u-vertical-align: (
  vertical-align: (
    base: "text",
    modifiers: null,
    values:
      map-collect(
        get-palettes($vertical-align-palettes),
        $vertical-align-manual-values
      ),
    settings: $vertical-align-settings,
    property: "vertical-align",
    type: "utility",
  ),
);
