/*
========================================
position
----------------------------------------
usage:
  .position-[key]
----------------------------------------
output:
  position: [value];
----------------------------------------
example:
  .position-absolute {
    position: absolute; }
----------------------------------------
*/

$u-position: (
  position: (
    base: "position",
    modifiers: null,
    values:
      map-collect(get-palettes($position-palettes), $position-manual-values),
    settings: $position-settings,
    property: "position",
    type: "utility",
  ),
);
