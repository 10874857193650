/*
========================================
circle
----------------------------------------
usage:
  .circle-[key]
----------------------------------------
output:
  height: [value];
  width: [value];
  border-radius: 50%;
----------------------------------------
example:
  .circle-6 {
    height: 3rem;
    width: 3rem; }
----------------------------------------
*/

$u-circle: (
  circle: (
    base: "circle",
    modifiers: null,
    values: map-collect(get-palettes($circle-palettes), $circle-manual-values),
    settings: $circle-settings,
    property: (
      height,
      width,
    ),
    extend: (
      "border-radius": "50%",
    ),
    type: "utility",
  ),
);
