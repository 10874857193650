/*
========================================
height
----------------------------------------
usage:
  .height-[value]
----------------------------------------
output:
  height: [value];
----------------------------------------
example:
  .height-33ct {
    height: 33.33333333%; }
----------------------------------------
*/

$u-height: (
  height: (
    base: "height",
    modifiers: null,
    values: map-collect(get-palettes($height-palettes), $height-manual-values),
    settings: $height-settings,
    property: "height",
    type: "utility",
  ),
);
