/*
========================================
LIST RESET
----------------------------------------
usage:
  .list-reset
----------------------------------------
output:
  list-style: none;
  padding-left: 0;
----------------------------------------
example:
  .list-reset {
    list-style: none;
    padding-left: 0; }
----------------------------------------
*/

$add-list-reset: (
  list-reset: (
    base: "add-list",
    modifiers: null,
    values: (
      reset: (
        slug: "reset",
        isReadable: true,
        content: "none",
        extend: (
          "margin-bottom": "0",
          "margin-top": "0",
          "padding-left": "0",
        ),
      ),
    ),
    settings: $add-list-reset-settings,
    property: "list-style",
    type: "object",
  ),
);
