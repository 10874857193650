/*
========================================
box-shadow
----------------------------------------
usage:
  .box-shadow-[value]
----------------------------------------
output:
  box-shadow: [value];
----------------------------------------
example:
  .box-shadow-1 {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10); }
----------------------------------------
*/

$u-box-shadow: (
  box-shadow: (
    base: "shadow",
    modifiers: null,
    values:
      map-collect(get-palettes($box-shadow-palettes), $box-shadow-manual-values),
    settings: $box-shadow-settings,
    property: "box-shadow",
    type: "utility",
  ),
);
